
import { Component, Vue, Watch } from 'vue-property-decorator'
import CustomDialog from '@/components/CustomDialog/CustomDialog.vue'
import ChecklistViewer from '@/components/ChecklistBuilder/Viewer/ChecklistViewer.vue'
import { Audit } from '@/components/ChecklistBuilder/Misc/audit.interface'
import { getService } from '@/helpers/feathers'
import { Connection } from '@/offline/connection.enum'
import { ImportSource } from '@/components/ChecklistBuilder/Misc/import-source.enum'
import BaseFrame from '@/components/BaseFrame/BaseFrame.vue'

/**
 * Test-Checklists-View.
 */
@Component({
  components: {
    CustomDialog,
    ChecklistViewer,
    BaseFrame
  }
})
export default class TestChecklists extends Vue {
  /**
   * Aktuell ausgewählte Ansicht der Collection innerhalb der Test-Checklisten.
   */
  public devCollectionStyle = ''

  /**
   * Der ChecklistViewer, der innerhalb der Test-Checklisten verwendet wird.
   * Da es für die ChecklistViwer kein passender Typ oder Interface exestiert,
   * wird der Typ `any` verwendet.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private checklistViewer: any = null

  /**
   * Beispiel Audit (offline)
   */
  public audit: Audit = {
    id: 2162 + 1,
    label: '[DUMMY] Tests-Audit - eplas10L',
    interval: 0,
    due: new Date('2020-03-31T00:00:00.000Z'),
    done: false,
    responsibleID: 1,
    executorID: null,
    participants: [],
    externals: '',
    createdFrom: 0,
    linkID: 0,
    checklists: [1117],
    // completedAt: null,
    // completedBy: null,
    inactive: false,
    affectedEmployeeID: null,
    categoryID: 41,
    mandatory: false,
    anonymous: false,
    // cloneOfID: null,
    createdBy: 1,
    createdAt: new Date('2020-03-31T11:38:43.640Z'),
    progressDone: 0,
    progressTotal: 0,
    labelPrefix: '',
    labelSuffix: '',
    // checklistLocationID: null
    importSource: ImportSource.Offline,
    importDate: new Date()
  }

  /**
   * Beispiel Audit (online)
   */
  public audit2: Audit = {
    id: 2162 + 0,
    label: '[DUMMY] Tests-Audit - eplas10L',
    interval: 0,
    due: new Date('2020-03-31T00:00:00.000Z'),
    done: false,
    responsibleID: 1,
    executorID: null,
    participants: [],
    externals: '',
    createdFrom: 0,
    linkID: 0,
    checklists: [1130],
    // completedAt: null,
    // completedBy: null,
    inactive: false,
    affectedEmployeeID: null,
    categoryID: null,
    mandatory: false,
    anonymous: false,
    // cloneOfID: null,
    createdBy: 1,
    createdAt: new Date('2020-03-31T11:38:43.640Z'),
    progressDone: 0,
    progressTotal: 0,
    labelPrefix: '',
    labelSuffix: ''
    // checklistLocationID: null
  }

  /**
   * Testfunktion, die alle Daten eines Audits löschen
   */
  public async syncDelete(): Promise<void> {
    const auditID: number = this.audit.id || 0
    const serviceChecklistVariableValues = await getService(
      'checklists/variables/values',
      Connection.Online
    )
    await serviceChecklistVariableValues.remove(auditID, {
      query: {
        $selector: 'audit'
      }
    })
    const serviceChecklistValues = await getService(
      'checklists/value',
      Connection.Online
    )
    await serviceChecklistValues.remove(auditID, {
      query: {
        $selector: 'audit'
      }
    })
    const serviceAuditLocations = await getService(
      'audit-location',
      Connection.Online
    )
    await serviceAuditLocations.remove(auditID)
    const serviceAuditProperties = await getService(
      'audit-property',
      Connection.Online
    )
    await serviceAuditProperties.remove(auditID)
    const serviceAuditChecklistStatus = await getService(
      'audit-checklist-status',
      Connection.Online
    )
    await serviceAuditChecklistStatus.remove(auditID)
    const serviceAudits = await getService('audit', Connection.Online)
    await serviceAudits.remove(auditID)
  }

  /**
   * Testfunktion, die die Variablen-Werte der Checkliste synchronisiert
   */
  public async syncChecklistVariables(): Promise<void> {
    const auditID: number = this.audit.id || 0

    const serviceOffline = await getService(
      'checklists/variables',
      Connection.Offline
    )
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dataOffline: any = await serviceOffline.find({
      query: {
        selector: `audit_${auditID}`
      }
    })

    // eslint-disable-next-line no-console
    console.log('sync variable value', dataOffline)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const values: any = (
      dataOffline && dataOffline.total > 0 ? dataOffline.data[0] : {}
    ).Values

    const service = await getService(
      'checklists/variables/values',
      Connection.Online
    )
    const result = await service.create({
      values: values,
      settings: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        selector_global: 'audit',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        selector_local: `audit_${auditID}`,
        date: new Date().toISOString(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        employee_id: 1,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        collection_id: 1117,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        emkg_id: null,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        substance_id: null,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        location_id: null,
        categoryID: 2,
        auditID: auditID,
        accidentReportID: null
      }
    })

    // eslint-disable-next-line no-console
    console.log('synced variable value', result)
  }

  /**
   * Testfunktion, die die Werte der Checkliste synchronisiert
   */
  public async syncChecklistValues(): Promise<void> {
    const auditID: number = this.audit.id || 0
    // eslint-disable-next-line no-console
    console.log('sync checklist values', auditID)

    const serviceChecklistValues = await getService(
      'checklists/cells/values',
      Connection.Offline
    )
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const valuesOffline: any = await serviceChecklistValues.find({
      query: {
        selector: `audit_${auditID}`,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        collection_id: (this.audit.checklists || [])[0] || 0
        // $moduleID: moduleId || 0 // Im offline-Modus nicht unterschützt
      }
    })

    const serviceCollection = await getService(
      'checklists-collections/deep/collections',
      Connection.Offline
    )
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const collection: any = await serviceCollection.get(
      (this.audit.checklists || [])[0] || 0
    )

    if (!collection || !Array.isArray(collection.Checklists)) {
      return
    }

    const service = await getService('checklists/value', Connection.Online)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any = []
    for (let checklist, i = 0; (checklist = collection.Checklists[i]); i++) {
      if (!Array.isArray(data[i])) {
        data[i] = []
      }
      const tempChecklist = data[i]
      for (let column, x = 0; (column = checklist.ChecklistCells[x]); x++) {
        if (!Array.isArray(tempChecklist[x])) {
          tempChecklist[x] = []
        }
        const tempColumn = tempChecklist[x]
        for (let cell, y = 0; (cell = column[y]); y++) {
          const value = valuesOffline[cell.id] || {}
          if (value.id > 0) {
            value.id = 0
          }
          tempColumn[y] = value
        }
      }
    }
    const result = await service.create({
      data: data,
      selector: `audit_${auditID}`,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      collection_id: (this.audit.checklists || [])[0] || 0,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      employee_id: 8145,
      logging: 1
    })

    // eslint-disable-next-line no-console
    console.log('synced checklist values', result)
  }

  /**
   * Testfunktion, die die Orte des Audits synchronisiert
   */
  public async syncAuditLocations(): Promise<void> {
    const auditID: number = this.audit.id || 0
    // eslint-disable-next-line no-console
    console.log('sync location', auditID)

    const serviceLocations = await getService(
      'checklists/location',
      Connection.Offline
    )
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dataOffline: any = await serviceLocations.get(auditID)
    const locations: number[] = dataOffline.locations

    const service = await getService('audit-location', Connection.Online)
    const data = []
    for (const locationID of locations) {
      data.push({
        auditID: auditID,
        locationID: locationID
      })
    }
    const result = await service.create(data)

    // eslint-disable-next-line no-console
    console.log('synced location', result)
  }

  /**
   * Testfunktion, die die Status der Checklisten des Audits synchronisiert
   */
  public async syncAuditChecklistStatus(): Promise<void> {
    const auditID: number = this.audit.id || 0
    // eslint-disable-next-line no-console
    console.log('sync checklist status', auditID)

    const servicePermissions = await getService(
      'checklists/permissions',
      Connection.Offline
    )
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dataPermissions: any = await servicePermissions.get(auditID)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const listStatus = dataPermissions.listStatus || []

    const serviceStatus = await getService(
      'checklists/status',
      Connection.Offline
    )
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dataStatus: any = await serviceStatus.get(auditID)
    for (const status of listStatus) {
      for (const key in dataStatus) {
        const checklistID = parseInt(key)
        if (checklistID && checklistID === status.id) {
          status.status = dataStatus[key]
          break
        }
      }
      status.checklistID = status.id
      delete status.id
      status.visibilityRights = status.visibility_rights
      delete status.visibility_rights
      status.editabilityRights = status.editability_rights
      delete status.editability_rights
      status.affectedEmployee = !!status.affected_employee
      status.executor = !!status.executor
      status.responsible = !!status.responsible
      delete status.affected_employee
    }

    const service = await getService(
      'audit-checklist-status',
      Connection.Online
    )
    const result = await service.create({
      auditID: auditID,
      status: listStatus
    })

    // eslint-disable-next-line no-console
    console.log('synced checklist status', result)
  }

  /**
   * Testfunktion, die die Audit list abfragt
   */
  public async listAudit(): Promise<void> {
    const params = {
      $orderBy: 'label',
      $orderDirection: 'desc',
      $offset: 0,
      $limit: 100,
      $search: 'Test'
    }
    // eslint-disable-next-line no-console
    console.log('find', params)

    const service = await getService('audit', Connection.Online)
    const result = await service.find({
      query: params
    })

    // eslint-disable-next-line no-console
    console.log('found', result)
  }

  /**
   * Testfunktion, die die Activities2Auditstabelle synchronisiert
   */
  public async syncAuditPropertys(): Promise<void> {
    const id: number = this.audit.id || 0
    const serviceActivities2Audits = await getService(
      'checklists/properties',
      Connection.Offline
    )
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dataOffline: any = await serviceActivities2Audits.get(id)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const properties: number[] = dataOffline.properties

    const service = await getService('audit-property', Connection.Online)
    const data = []
    for (const property of properties) {
      data.push({
        auditID: id,
        propertyID: property
      })
    }
    const result = await service.create(data)
    // eslint-disable-next-line no-console
    console.log('synched', result)
  }

  /**
   * Testfunktion, die als Callback übergeben werden kann
   *
   * @returns audit
   */
  public async createAudit(): Promise<Audit> {
    // eslint-disable-next-line no-console
    console.log('create', this.audit2)

    const service = await getService('audit', Connection.Online)
    const result = await service.create(this.audit2)

    // eslint-disable-next-line no-console
    console.log('created', result)
    return result as Audit
  }

  /**
   * Testfunktion, die als Callback übergeben werden kann
   *
   * @returns audit
   */
  public async importAudit(): Promise<Audit> {
    // eslint-disable-next-line no-console
    console.log('import', this.audit)

    const service = await getService('audit', Connection.Online)
    const result = await service.create(this.audit)

    // eslint-disable-next-line no-console
    console.log('imported', result)
    return result as Audit
  }

  /**
   * Vue-Hook 'mounted'
   */
  private mounted(): void {
    this.checklistViewer = this.$refs.checklistViewer

    const builder = this.checklistViewer.checklistBuilder

    switch (this.checklistViewer.collection.display_type) {
      case builder.DISPLAY_TYPE_LIST:
        this.devCollectionStyle = 'list'
        break

      case builder.DISPLAY_TYPE_ACCORDION:
        this.devCollectionStyle = 'tabs'
        break

      case builder.DISPLAY_TYPE_TABS:
        this.devCollectionStyle = 'accordion'
        break
    }
  }

  /**
   * Watcher für die Eigenschaft [[devCollectionStyle]].
   * Es werden die Änderungen an die Checkliste weiter gereicht. Dies passiert
   * aber auch nur, wenn der gesetzte Wert auch gültig ist.
   *
   * @param value - aktuelle Wert von [[devCollectionStyle]]
   * @param oldValue - vorherige Wert von [[devCollectionStyle]]
   */
  @Watch('devCollectionStyle')
  public onDevCollectionStyle(value: string, oldValue: string): void {
    if (this.checklistViewer && oldValue !== '' && value !== oldValue) {
      const builder = this.checklistViewer.checklistBuilder
      const collection = this.checklistViewer.collection

      switch (value) {
        case 'list':
          // eslint-disable-next-line @typescript-eslint/naming-convention
          collection.display_type = builder.DISPLAY_TYPE_LIST
          break

        case 'tabs':
          // eslint-disable-next-line @typescript-eslint/naming-convention
          collection.display_type = builder.DISPLAY_TYPE_TABS
          break

        case 'accordion':
          // eslint-disable-next-line @typescript-eslint/naming-convention
          collection.display_type = builder.DISPLAY_TYPE_ACCORDION
          break
      }
    }
  }
}
